import React from 'react'
import bg from '../../assets/images/bg/paralax/bg-image-3.jpg'

const Blog5 = () => {
  return (
    <>
      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
        data-black-overlay="7"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-single-page-title text-center pt--100">
                <h2 className="title theme-gradient">
                  TypeScript is the Only Programming Language you Need:
                  TypeScript Everywhere
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      {/* Start Blog Details */}
      <div className="rn-blog-details pt--110 pb--70 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner">
                  <p className="mt--20">
                    TypeScript has become extremely popular among JavaScript
                    developers and more and more projects use it as the primary
                    programming language.
                  </p>
                  <p>
                    Today, most frameworks or libraries have support for
                    TypeScript, which makes it easy to use.
                  </p>

                  <h3>TypeScript Everywhere</h3>

                  <p className="mt--40">
                    JavaScript/TypeScript can be executed on every platform. You
                    can now use TypeScript for all your projects without any
                    limitation: one programming language to rule them all!
                  </p>
                  <p>
                    It also means you only need to learn one programming
                    language for all your projects. For any senior developer
                    learning a new programming language isn't difficult but the
                    time to master one is time-consuming.
                  </p>
                  <p>
                    You only need to use one ecosystem: one package manager, one
                    linter, one code formatter, etc. You only need to configure
                    once and share it across all your projects. Saving you a ton
                    of time ;)
                  </p>
                  <h3>TypeScript for Frontend</h3>
                  <p>
                    The most used framework to build user interfaces is React
                    and Vue.js. Both now support TypeScript.
                  </p>
                  <p>
                    I'm a React developer, I won't be able to say much about
                    Vue.js. But when I started to use TypeScript with React, it
                    wasn't an easy process because I was doing two things:
                  </p>
                  <ul>
                    <li>Learning TypeScript</li>
                    <li>Applying TypeScript in React context</li>
                  </ul>

                  <p className="mb--0">
                    In the beginning, you'll definitively lose your time
                    fighting against TypeScript. But, once you understand the
                    basics, it makes your developer life much easier. It helps
                    you to write better code with fewer errors.
                  </p>
                  <p>
                    I like to write small components and you should too if you
                    want to make your React code more modular. By using
                    TypeScript on top of React components makes the code easy to
                    navigate and maintain.
                  </p>
                  <p>
                    You can start your React projects with all modern tools like
                    React, Next JS, TypeScript, Tailwind CSS with great tooling
                    like ESLint, Prettier and Husky configured for you.
                  </p>
                  <h3>TypeScript for Backend</h3>
                  <p>
                    In the past, you need to write in different programming
                    languages for the frontend and backend. The backend was
                    mostly written in Java, PHP, Ruby, etc.
                  </p>
                  <p>
                    It makes the development experience not so great and the
                    developers need to do a lot of context switching between
                    frontend development and backend. The experience wasn't
                    unified.
                  </p>
                  <p>
                    Node JS made it possible to write backend code with
                    JavaScript. You can now write your REST API in the same
                    language as your frontend.
                  </p>
                  <p>
                    Not only do you get the same development tools for both
                    environments, but you can also even share the same code
                    between your two environments. You don't need to write the
                    same logic twice.
                  </p>
                  <p>
                    There are some edge cases where you aren't able to share the
                    code due to the nature of the platform. Some packages or
                    some APIs can only work in the browser. So, you couldn't run
                    it with Node JS. On the opposite, some packages can only
                    work in Node JS. This case is extremely rare but you need to
                    be aware of it.
                  </p>
                  <h3>TypeScript for Infra as Code</h3>
                  <p>
                    Several years ago, I used to rent a dedicated server to host
                    my full-stack application based on the LAMP stack. It wasn't
                    an easy task to manually configure everything from scratch.
                  </p>
                  <p>
                    First, you need to install the dependencies, secure your
                    server, update the configuration file, etc. The most
                    annoying thing is to maintain it.
                  </p>
                  <p>
                    The Infrastructure as Code (IaC) was the ideal solution to
                    solve this issue. It makes the process smoother using
                    definition files. For someone with a developer background,
                    you want to version control your infrastructure resources
                    and remove the repetitive task when configuring several
                    servers. The IaC is the perfect tool.
                  </p>
                  <p>
                    With recent development, a new wave of IaC has emerged where
                    you can use your favorite programming language instead of
                    using configuration/definition files. Recently, I started to
                    use AWS CDK with TypeScript to define my cloud resources.
                  </p>
                  <p>
                    AWS CDK makes learning extremely easy: you don't need to
                    learn a new tool and new ecosystem. You can still use your
                    favorite JavaScript package manager, tooling and it works
                    perfectly well with your favorite editor (my favorite one is
                    VSCode 😜). Because I use it with TypeScript, I know when
                    there is an error or when I misuse a function.
                  </p>
                  <h3>TypeScript for Mobile Development</h3>
                  <p>
                    With hybrid application and React Native, JavaScript isn't
                    limited to the web application. You can now start building
                    iOS and Android applications with JavaScript. So, you can
                    now use TypeScript without any issue to create your future
                    apps.
                  </p>
                  <p>
                    Unfortunately, I'm not able to share much about mobile
                    development with TypeScript. In the past, I've done some
                    native mobile development on iOS in objective-C (pretty old
                    school, before Swift) but not on React Native.
                  </p>
                  <p>
                    If I had the opportunity to make a mobile development, I'll
                    definitively try React Native with TypeScript because I'm
                    already familiar with React and TypeScript. I hope it makes
                    the process easier and smoother. And on top of that, I also
                    want to use Expo, a React Native framework. The framework
                    seems to be very promising and it's considering a
                    NextJS-like for mobile development.
                  </p>
                  <h3>TypeScript for Machine Learning</h3>
                  <p>
                    Even if Python has a much larger ecosystem and community,
                    you can also use TypeScript for Machine Learning. I've built
                    a small OCR using React and Tensorflow.js for digits number.
                  </p>
                  <p>
                    It's possible to train the model directly inside the
                    browser, but the result wasn't great. It was slow to train
                    the model and the accuracy of the prediction drop. You
                    should train your model on a more powerful computer and only
                    load the trained model when needed. It's still great to have
                    the ability to run a machine learning model directly inside
                    your browser, it makes your integration into your existing
                    project easier.
                  </p>

                  <h3>In conclusion</h3>
                  <p>
                    TypeScript can now be used everywhere and make the
                    development much smoother with only one programming language
                    to learn. No more context switching between programming
                    language and all the stack is unified.
                  </p>
                  <p>
                    After showing the benefits of TypeScript, I hope you'll be
                    able to use it in your projects and start using it in your
                    daily work. So, I've written an article on how to set up
                    TypeScript with ESLint, Prettier in React projects.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Blog Details */}
    </>
  )
}

export default Blog5
