import React from 'react'
import bg from '../../assets/images/bg/paralax/bg-image-3.jpg'

const Blog4 = () => {
  return (
    <>
      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
        data-black-overlay="7"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-single-page-title text-center pt--100">
                <h2 className="title theme-gradient">On let vs const</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      {/* Start Blog Details */}
      <div className="rn-blog-details pt--110 pb--70 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner">
                  <p className="mt--20">
                    let vs const vs var: Usually you want let. If you want to
                    forbid assignment to this variable, you can use const. (Some
                    codebases and coworkers are pedantic and force you to use
                    const when there is only one assignment.)
                  </p>
                  <p>
                    This turned out to be very controversial, sparking
                    conversations on Twitter and Reddit. It seems that the
                    majority view (or at least, the most vocally expressed view)
                    is that one should use const wherever possible, only falling
                    back to let where necessary, as can be enforced with the
                    prefer-const ESLint rule.
                  </p>
                  <p>
                    In this post, I will briefly summarize some of the arguments
                    and counter-arguments I’ve encountered, as well as my
                    personal conclusion on this topic.
                  </p>

                  <h3>Why prefer-const</h3>
                  <ul>
                    <li>
                      One Way to Do It: It is mental overhead to have to choose
                      between let and const every time. A rule like “always use
                      const where it works” lets you stop thinking about it and
                      can be enforced by a linter.
                    </li>
                    <li>
                      Reassignments May Cause Bugs: In a longer function, it can
                      be easy to miss when a variable is reassigned. This may
                      cause bugs. Particularly in closures, const gives you
                      confidence you’ll always “see” the same value.
                    </li>
                    <li>
                      Learning About Mutation: Folks new to JavaScript often get
                      confused thinking const implies immutability. However, one
                      could argue that it’s important to learn the difference
                      between variable mutation and assignment anyway, and
                      preferring const forces you to confront this distinction
                      early on.
                    </li>
                    <li>
                      Meaningless Assignments: Sometimes, an assignment doesn’t
                      make sense at all. For example, with React Hooks, the
                      values you get from a Hook like useState are more like
                      parameters. They flow in one direction. Seeing an error on
                      their assignment helps you learn earlier about the React
                      data flow.
                    </li>
                    <li>
                      Performance Benefits: There are occasional claims that
                      JavaScript engines could make code using const run faster
                      due to the knowledge the variable won’t be reassigned.
                    </li>
                  </ul>

                  <h3>Why not prefer-const</h3>
                  <ul>
                    <li>
                      Loss of Intent: If we force const everywhere it can work,
                      we lose the ability to communicate whether it was
                      important for something to not be reassigned.
                    </li>
                    <li>
                      Confusion with Immutability: In every discussion about why
                      you should prefer const, someone always confuses with
                      immutability. This is unsurprising, as both assignment and
                      mutation use the same = operator. In response, people are
                      usually told that they should “just learn the language”.
                      However, the counter-argument is that if a feature that
                      prevents mostly beginner mistakes is confusing to
                      beginners, it isn’t very helpful. And unfortunately, it
                      doesn’t help prevent mutation mistakes which span across
                      modules and affect everyone.
                    </li>
                    <li>
                      Pressure to Avoid Redeclaring: A const-first codebase
                      creates a pressure to not use let for conditionally
                      assigned variables. For example, you might write const a =
                      cond ? b : c instead of an if condition, even if both b
                      and c branches are convoluted and giving them explicit
                      names is awkward.
                    </li>
                    <li>
                      Reassignments May Not Cause Bugs: There are three common
                      cases when reassignments cause bugs: when the scope is
                      very large (such as module scope or huge functions), when
                      the value is a parameter (so it’s unexpected that it would
                      be equal to something other than what was passed), and
                      when a variable is used in a nested function. However, in
                      many codebases most variables won’t satisfy either of
                      those cases, and parameters can’t be marked as constant at
                      all.
                    </li>
                    <li>
                      No Performance Benefits: It is my understanding that the
                      engines are already aware of which variables are only
                      assigned once — even if you use var or let. If we insist
                      on speculating, we could just as well speculate that extra
                      checks can create performance cost rather than reduce it.
                      But really, engines are smart.
                    </li>
                  </ul>
                  <h3>My Conclusion</h3>
                  <p>I don’t care.</p>
                  <p>
                    I would use whatever convention already exists in the
                    codebase.
                  </p>
                  <p>
                    If you care, use a linter that automates checking and fixing
                    this so that changing let to const doesn't become a delay in
                    code review.
                  </p>
                  <p>
                    Finally, remember that linters exist to serve you. If a
                    linter rule annoys you and your team, delete it. It may not
                    be worth it. Learn from your own mistakes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Blog Details */}
    </>
  )
}

export default Blog4
