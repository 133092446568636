import React from 'react'
import bg from '../../assets/images/bg/paralax/bg-image-3.jpg'

const Blog2 = () => {
  return (
    <>
      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
        data-black-overlay="7"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-single-page-title text-center pt--100">
                <h2 className="title theme-gradient">
                  Unix vs Windows Commands Cheat Sheet
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      {/* Start Blog Details */}
      <div className="rn-blog-details pt--110 pb--70 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner">
                  <p className="mt--20">
                    I recently made a switch on development teams that I was
                    working with which took me from working on my MacBook to
                    needing to work on a windows machine. It had been over a
                    year or so since I last had to work extensively in windows
                    and so I made myself a quick little cheat sheet on a note to
                    help me recall the most basic command parallels between Unix
                    and Windows.
                  </p>
                  <p>
                    If you find this useful, you can bookmark it and use it in
                    the future. I will most likely keep coming through and
                    updating this article to include a more extensive list of
                    command comparisons.
                  </p>

                  <p>
                    <code>|Function|UNIX|Windows|</code>
                  </p>
                  <p>
                    <code>|--------|----|-------|</code>
                  </p>
                  <p>
                    <code>|Change Directory|`cd`|`cd`|</code>
                  </p>
                  <p>
                    <code>|List directory contents|`ls`|`dir`|</code>
                  </p>
                  <p>
                    <code>|Print working directory|`pwd`|`cd`|</code>
                  </p>
                  <p>
                    <code>|Copy file or directory|`cp`|`copy`|</code>
                  </p>
                  <p>
                    <code>|Move or rename file or directory|`mv`|`move`|</code>
                  </p>
                  <p>
                    <code>|Display contents of file|`cat`|`type`|</code>
                  </p>
                  <p>
                    <code>|Search for pattern in file(s)|`grep`|`find`|</code>
                  </p>
                  <p>
                    <code>|Terminate process|`kill`|`taskkill`|</code>
                  </p>
                  <p>
                    <code>
                      |Display Information about process|`ps`|`tasklist`|
                    </code>
                  </p>
                  <p>
                    <code>|Change permissions|`chmod`|`cacls`|</code>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Blog Details */}
    </>
  )
}

export default Blog2
