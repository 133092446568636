const BlogContent = [
  {
    images: '01',
    title: 'Software Developer Career Roadmap',
    category: 'Tech',
  },
  {
    images: '02',
    title: 'Unix vs Windows Commands Cheat Sheet',
    category: 'Tech',
  },

  {
    images: '03',
    title: 'What exactly is vanilla JavaScript?',
    category: 'Tech',
  },
  {
    images: '04',
    title: 'On let vs const',
    category: 'Tech',
  },
  {
    images: '05',
    title: 'TypeScript is the Only Programming Language you Need',
    category: 'Tech',
  },
  {
    images: '06',
    title: 'Finding the Right Mindset as a Junior Developer',
    category: 'Tech',
  },
]

export default BlogContent
