import React from 'react'
import bg from '../../assets/images/bg/paralax/bg-image-3.jpg'

const Blog3 = () => {
  return (
    <>
      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
        data-black-overlay="7"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-single-page-title text-center pt--100">
                <h2 className="title theme-gradient">
                  What exactly is vanilla JavaScript?
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      {/* Start Blog Details */}
      <div className="rn-blog-details pt--110 pb--70 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner">
                  {/* <div className="thumbnail">
                    <img src={blog3Image} alt="Blog Images" />
                  </div> */}
                  <br />
                  <h3>The term vanilla</h3>
                  <p className="mt--20">
                    The term vanilla originates from the traditional standard
                    flavor of ice cream, vanilla flavor. It's an adjective that
                    describes the simplest version of something, without any
                    optional extras
                  </p>
                  <p>
                    Various computing-related things like software, hardware or
                    algorithms are called vanilla when they are not customized,
                    but remain in their original, basic form. In this usage
                    context, the word vanilla is used synoymously to the words
                    ordinary or standard:
                  </p>
                  <h3>What's included in vanilla JavaScript?</h3>
                  <p>
                    There is no official definition of vanilla JavaScript or
                    vanilla JS. These terms refer to the language itself,
                    without any external additions like libraries, frameworks or
                    other packages. Because JavaScript is standardized as
                    ECMAScript, vanilla JavaScript includes the standard
                    language features described in the ECMA-262 specification.
                  </p>
                  <p>
                    Additional APIs are assumed to be present when programming
                    JavaScript, depending on the context.
                  </p>
                  <ul>
                    <li>
                      JavaScript in a browser, when JavaScript is running in the
                      browser, additional Web APIs like the DOM and CSSOM or the
                      fetch and canvas APIs are available.
                    </li>
                    <li>
                      JavaScript in Node.js, When JavaScript is running in
                      Node.js, additional Node APIs like HTTP or the file system
                      API are available.
                    </li>
                  </ul>
                  <p>
                    Because both Web and Node APIs are not external additions,
                    JavaScript code using them is typically also considered
                    vanilla JavaScript. The combination of Node.js APIs and the
                    ECMAScript standard is sometimes referred to as vanilla
                    Node.
                  </p>

                  <h3>It's not a framework</h3>
                  <p>
                    The term vanilla JS is often used as a joke to remind other
                    developers that a lot of things can be easily done without
                    the need for additional JavaScript libraries or packages.
                  </p>
                  <p>
                    There's even a website that builds upon this joke by
                    attempting to market vanilla JS as a "very fast framework".
                    Even though reminding developers that a lof of things can be
                    done without using additional libraries, this is very
                    confusing to beginners.
                  </p>
                  <p>
                    Don't get confused! Vanilla JavaScript is not a framework.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Blog Details */}
    </>
  )
}

export default Blog3
