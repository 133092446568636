import React from 'react'
import bg from '../../assets/images/bg/paralax/bg-image-3.jpg'

const Blog1 = () => {
  return (
    <>
      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
        data-black-overlay="7"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-single-page-title text-center pt--100">
                <h2 className="title theme-gradient">
                  Software Developer Career Roadmap
                </h2>
                {/* <ul className="blog-meta d-flex justify-content-center align-items-center">
                  <li>
                    <FiClock />
                    Mar 14, 2022
                  </li>
                  <li>
                    <FiUser />
                    Bo Bleyl
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      {/* Start Blog Details */}
      <div className="rn-blog-details pt--110 pb--70 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner">
                  <p className="mt--20">
                    Software developers have many different roles and titles. As
                    a new developer, it can be confusing as to what kinds of
                    positions you might qualify for and what kind of career
                    roadmap you can expect to see and follow.
                  </p>
                  <p>
                    I’m going to lay out the different career paths available to
                    developers, what kinds of career changes you can expect, and
                    what all of these different job titles actually mean.
                  </p>
                  <p>
                    The reality of software development is that there are many
                    different areas in which you can specialize: Frontend,
                    Backend, Security, DevOps, Full-stack, etc.
                  </p>

                  <p className="mt--40">
                    Despite many different specialties within software
                    development, each of these paths has roughly the same core
                    roles and progression. The most common of which being,
                    Intern, Junior Developer, Software Developer, and Senior
                    Software Developer.
                  </p>
                  <p>
                    We are going to cover the different titles that correspond
                    with these core roles and what responsibilities you can
                    expect to have.
                  </p>
                  <p>
                    It should be mentioned that you don’t necessarily need to
                    step through each of these roles, but that these roles
                    represent the various stages of responsibility software
                    developers can expect to have throughout their careers.
                  </p>
                  <p>
                    For those interested in furthering their career past the
                    role of sr developer, be sure to keep reading through the
                    sections below: Individual Contributor(IC) Career Path and
                    People Management Career Path. Both of these paths stem from
                    these core roles and are fairly uniform regardless of your
                    specialty as a developer.
                  </p>

                  <h3>Developer / Engineer / Programmer</h3>
                  <p>
                    One important note in regards to the different titles you
                    will hear: is that in the tech industry, the terms
                    developer, engineer and programmer are often used
                    interchangeably. Some will contest that there are clear
                    differences between titles with these distinctions, but
                    generally speaking, these 3 terms mean roughly the same
                    thing.
                  </p>

                  <h3>Intern</h3>
                  <p className="mb--0">
                    For many developers just starting out, the first role that
                    they will have is that of an intern. Software development
                    interns typically are given a 3–6 month window in which they
                    get to work with other developers on a small to mid-sized
                    project that gets them experience in the languages and
                    technologies that a company uses. Often these internships
                    occur over the summer months, may-august, while most
                    students are out of school and spring job-recruitment season
                    for new developers is wrapping up.
                  </p>

                  <p>
                    Most companies use these internships for finding developers
                    that are a good fit for their organization. Many interns are
                    also given the same job title as a company’s entry-level
                    developers such as Associate Developer or Junior Developer.
                  </p>
                  <h3>Junior / Associate Software Developer</h3>
                  <p>
                    Junior (or Associate) Software Developer is a title that’s
                    typically used for developers who are just starting out and
                    require additional resources and time to get up to the same
                    quantity and quality of output as a normal developer.
                  </p>
                  <p>
                    The term Junior Developer is used quite regularly in
                    companies to define their entry-level positions for software
                    development. This title can vary slightly depending on the
                    company, but the other commonly used title is Entry Level
                    *Insert Language or Framework* Developer.
                  </p>
                  <p>
                    Despite the lower expectation of output, there is a high
                    expectation that over the course of your first year in this
                    position you will gain the skills needed to progress to the
                    next role: Software Developer.
                  </p>
                  <h3>Software Developer</h3>
                  <p>
                    A Software Developer is someone who has one or more years of
                    professional software development experience and is able to
                    handle tasks independently of others.
                  </p>
                  <p>
                    Usually, the barrier between Junior developers and regular
                    developers is their ability to handle “most” tasks,
                    independently. There will be times where pair-a-programming
                    or advice is needed to move things along quickly, but most
                    often, software developers can handle their own tasks
                    independent of outside help.
                  </p>
                  <p>
                    Some other common titles used to describe this role are:
                    Software Engineer, *Insert Language or Framework* Developer,
                    or just Developer. Some larger corporations will assign a
                    numerical value to their devs to signify how experienced
                    they are within their dev teams such as Software Engineer I,
                    Software Engineer II, etc. There is some minor debate over
                    the significance of differentiating Software Developers from
                    Software Engineers, but most will agree that these titles
                    typically carry the same weight.
                  </p>
                  <h3>Senior Software Developer</h3>
                  <p>
                    Senior Software Developer is typically the main role that
                    most devs aim for in their career. Although the titles often
                    vary greatly from company to company it signifies that you
                    are a highly skilled developer. In most cases, Senior
                    Developers have 5+ years of professional software
                    development experience.
                  </p>
                  <p>
                    You’ll often see titles defined as Senior *insert language
                    or framework* Developer.
                  </p>
                  <p>
                    Once you’ve reached the role of Senior Developer, there are
                    two different career paths you can take should you wish to
                    progress your career even further, both of which you can
                    learn more about in the next sections: The Individual
                    Contributor Career Path and The People Management Career
                    Path.
                  </p>
                  <h3>Individual Contributor (IC) Career Path</h3>
                  <p>
                    For those who enjoy coding and want to remain in software
                    development while still progressing with their pay and
                    status, the Individual Contributor (IC) Career Path is
                    ideal.
                  </p>
                  <p>
                    At a glance, IC’s mainly are focused on improving their
                    existing skillsets and continuing to take on more
                    responsibilities within their dev teams. Although they
                    haven’t chosen a management path, IC’s are still expected to
                    be a leader and mentor developers within the teams and
                    organizations they work with. There are a few types of roles
                    that IC’s can progress to that have more clearly defined
                    expectations for this type of leadership. Those two main
                    roles they can progress into are Team Lead and Software
                    Architect.
                  </p>
                  <h3>Team Lead</h3>
                  <p>
                    Team Leads are exactly what the title sounds like, leaders.
                    They take on the added responsibility of being the developer
                    in charge of executing the technical side of a project. This
                    typically entails being in charge of code reviews, helping
                    coordinate how different features will come together, etc.
                    Other similar titles can be known as Project Lead, Lead
                    Developer, etc.
                  </p>
                  <h3>Software Architect</h3>
                  <p>
                    For those mainly interested in getting even deeper into
                    software development and design, the next step for them
                    after Senior Developer is that of Software Architect.
                    Architects often deal more with the overarching structure of
                    systems and how they interact and work with each other. They
                    make important decisions for which technologies will be used
                    and implemented on projects. Depending on the company size,
                    responsibilities in this role can vary.
                  </p>
                  <p>
                    Both of these roles can be a great jumping-off point for
                    progressing into upper management positions, being that they
                    give credibility to a developer’s skills and experience in
                    the field.
                  </p>
                  <p>
                    Unless you’re looking to transition into management
                    positions, this is often the furthest down the career tree
                    you can get while remaining a software developer and being
                    hands-on with development.
                  </p>
                  <p>
                    If you have a desire to get into people management
                    positions, keep reading below in the People Management
                    Career Path section.
                  </p>
                  <h3>People Management Career Path</h3>
                  <p>
                    People management encompasses all types of roles associated
                    with the leadership and execution of teams. It’s a step
                    aside from hands-on development and a step onto the
                    management career ladder.
                  </p>
                  <p>
                    For those interested in leadership positions or with
                    aspirations of working in company management, this career
                    path is ideal for them.
                  </p>
                  <p>
                    People management encompasses various roles: software
                    development managers, project or product managers, director
                    positions, and various C-Suite positions. It’s important to
                    note that one doesn’t have to step through each of these
                    positions to progress, but that these are simply the most
                    common roles one can expect to have if they choose a people
                    management career path.
                  </p>
                  <h3>Software Development Manager</h3>
                  <p>
                    Software Development Managers are roles more commonly seen
                    at larger companies. Their main responsibilities are to
                    manage and assist the software developers. This can entail:
                  </p>
                  <ul>
                    <li>hiring/training new developers</li>
                    <li>setting/managing goals in development teams</li>
                    <li>monitoring and assigning tasks</li>
                    <li>
                      making plans, reports, and other aids for both the
                      software development teams as well as upper management
                    </li>
                    <li>
                      In some cases, these managers will be in charge of heading
                      up communication with clients
                    </li>
                  </ul>
                  <h3>Project / Product Manager</h3>
                  <p>
                    Similar to Software Development Managers, project and
                    product managers have many of the same responsibilities.
                    Their core responsibilities however are to take the lead of
                    a given team or project, divide out the necessary work, and
                    manage the efforts to see that project through.
                  </p>
                  <p>
                    You can expect to communicate frequently with clients and
                    convey the concerns and questions of the development team to
                    the client, and like-wise, the concerns and questions of the
                    client to the development team.
                  </p>
                  <p>
                    Ultimately, the Product or Project Manager is responsible
                    for the success or failure of a particular project and/or
                    team.
                  </p>
                  <h3>Upper Management (CTO, Director of Engineering, etc.)</h3>
                  <p>
                    For those desiring to progress beyond the responsibilities
                    of a manager, the next step is to move up into a position in
                    Upper Management. Another path to get to upper management is
                    through becoming a Software Architect, a role we discussed
                    in the Individual Contributor Career Path Video, also found
                    in this series.
                  </p>
                  <p>
                    The types of positions available in upper management depend
                    on the size of the company. In smaller companies ({'<'}200
                    employees), you'll often see Chief Technology Officer as the
                    main and sometimes only role available.
                  </p>
                  <p>
                    In larger companies, however, there are various
                    opportunities in positions as a director of engineering or
                    director of various departments within the company. Some
                    also use the title of “Vice President of Engineering, etc.”.
                    These titles often depend on the company structure. The
                    roles and responsibilities of these positions are very
                    similar, despite having different titles.
                  </p>
                  <p>
                    VPs and Directors are often responsible for everything
                    occurring internally in a company in regards to software
                    development endeavors. They work with product/project
                    managers to ensure projects are moving along smoothly and
                    identify areas that need improvement within the companies
                    processes.
                  </p>
                  <p>
                    CTOs on the other hand typically work mostly with other
                    C-Suite level employees as well as shareholders. Their
                    responsibilities are to be company evangelists and their
                    day-to-day activities often revolve more around networking
                    and going to board meetings. VPs and Directors of
                    Engineering report to the CTO and apprise them of everything
                    going on internally in regards to software development
                    efforts.
                  </p>
                  <p>
                    If a company is smaller, then oftentimes you’ll see CTOs
                    take on all of the responsibilities we listed for VPs and
                    Directors. Many times, you’ll even see CTOs at startups
                    still involved in the actual hands-on development of
                    products, meaning, they are actively contributing to
                    building out these products. As startups grow into larger
                    companies, these types of responsibilities get handed off to
                    others, as their scope of focus broadens.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Blog Details */}
    </>
  )
}

export default Blog1
