import React from 'react'
import bg from '../../assets/images/bg/paralax/bg-image-3.jpg'

const Blog6 = () => {
  return (
    <>
      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
        data-black-overlay="7"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-single-page-title text-center pt--100">
                <h2 className="title theme-gradient">
                  Finding the Right Mindset as a Junior Developer
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      {/* Start Blog Details */}
      <div className="rn-blog-details pt--110 pb--70 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner">
                  <p className="mt--20">
                    As a new developer it can be confusing navigating all the
                    available programming languages, frameworks, do’s and
                    don’ts, buzzwords etc. In addition to this you have as many
                    opinions as there are developers. Depending on who you
                    follow, what blogs you read or what teacher you had - your
                    path as a developer is determined by the opinions of those
                    people.
                  </p>
                  <p>
                    I firmly believe that the most important skills as an
                    aspiring developer is to have an open mind, be willing to
                    try new things and keep learning. Don’t get stuck on
                    opinions of others. The phrase “you should start with
                    vanilla JavaScript before even trying out a framework”
                    mindset is dangerous. It’s fine to start with a framework.
                    One huge motivation factor is to have fun while making
                    progress. If you find yourself enjoying working in a React
                    application. Don’t get thrown off that path because some
                    people say that you are starting in the wrong end. As long
                    as you are building, you are learning.
                  </p>
                  <p>Solve problems</p>

                  <p className="mt--40">
                    When learning programming you should not narrow down your
                    thinking to a specific language. Instead think of it as you
                    are solving problems. Programming languages are there for
                    you, to help you solve problems. So what problems are we
                    talking about? In this context you have some kind of goal
                    with what you are building. Say you want to build a website
                    to learn concepts about web development. To achieve that
                    goal you need to solve problems along the way. When setting
                    up your first website you need to start somewhere. It might
                    be confusing by all the available alternatives out there.
                    The important thing here is to start. Whether it’s by
                    building a simple indexl.html file or a Vue application for
                    example. Whatever makes you interested in the process and
                    that makes you accomplish something.
                  </p>
                  <p>
                    Another example of something you might need to solve is
                    triggering an action when a user clicks a button. How will
                    you do that? If you are building the website with Vue for
                    example, you would search for how to add a click event to a
                    button in Vue. Get your answer from Stack Overflow or the
                    Vue documentation and add it to your application. At this
                    point you don’t need to understand what the actual click
                    event in Vue are doing. You managed to get something to
                    happen when you clicked the button - problem solved.
                  </p>
                  <p>
                    When you are comfortable with handling events in Vue you
                    could look them up in detail. But only when you find that
                    interesting enough to investigate. Then you could try and
                    implement the same event without a framework. Both ways of
                    going at this are completely fine. Find your way of solving
                    problems. Have fun along the way.
                  </p>
                  <p>
                    Going forward. Instead of thinking that you are learning Vue
                    in this case, think of it as you are learning to build a
                    website with the help of the framework Vue. You could also
                    be building the same website with any other framework. Or
                    plain HTML, CSS and JavaScript. Your mission is to build
                    that website. Of course you need to learn Vue to build a
                    website with Vue. But you should think of your learning in a
                    broader perspective.
                  </p>
                  <p>
                    When reading blog posts or scrolling through Twitter I see
                    that lots of people are recommending to completely learn
                    HTML, CSS and JavaScript before even touching a framework. I
                    do not think this is necessary. Although, at some point in
                    your career you will have to learn the basic concepts, but
                    for a developer starting out you will gain more from
                    continuing with the framework you started with and solve
                    problems. The more you build the more you will learn and in
                    the end will make you grow as a developer.
                  </p>
                  <h3>Must I choose one specific framework?</h3>
                  <p>
                    No, you don’t. It could be dangerous to identify yourself as
                    a React developer or a Vue developer. You should identify
                    yourself as a web developer that solve problems.
                  </p>
                  <p>
                    Although you have to start somewhere. In that sense I think
                    it’s preferable to learn one framework to a decent level
                    before learning the next. It’s better to be great at React
                    than have basic knowledge of many frameworks. You still need
                    to understand the differences between the frameworks and
                    what problems they are solving. When seeing the languages
                    and frameworks as tools to help you solve problems you can
                    choose the best tool for the problem you are facing.
                  </p>
                  <h3>Learn by doing</h3>
                  <p>
                    You can read multiple books or watch hours of tutorials but
                    the absolute best way to learn is by doing. I can’t say this
                    enough. Get started and build something. The more you build
                    the more situations and problems you will encounter. It’s by
                    solving those problems that will make you grow as a
                    developer.
                  </p>
                  <p>
                    When building projects you will also build a portfolio. A
                    platform to both show off your skills and to try new out new
                    things. You will gain a lot from having your own website as
                    a base for this. You will start to have an online presence
                    and have something to show in upcoming interviews. Although
                    I do not think that having an online presence is required at
                    all. If reading Twitter there are many people talking about
                    the importance of online presence. I don’t think that is for
                    everyone. You will probably get some opportunities and meet
                    people that you otherwise wouldn’t. But it can also be very
                    stressful. Focus on building and learning first. And if you
                    are comfortable and willing it could be fun to show other
                    what you are learning.
                  </p>
                  <p>
                    Another underrated aspect is to take inspiration from
                    others. GitHub is a huge opportunity to learn from the
                    industry. There are so many projects that are open sourced
                    that you can watch and learn from. Find a project that uses
                    the same stack or framework that you’re interested in and
                    clone that repo.
                  </p>
                  <h3>Summary</h3>
                  <p>
                    I would like to summarise my rambling above in to 5 bullet
                    points:
                  </p>
                  <ul>
                    <li>Learn by doing</li>
                    <li>Find your path</li>
                    <li>Build, build, build</li>
                    <li>Build, build, build</li>
                    <li>Don’t get stuck in other peoples opinions</li>
                    <li>Have fun</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Blog Details */}
    </>
  )
}

export default Blog6
